/* eslint-disable @next/next/no-img-element */
import React from "react";
import LogoHeader from "../components/logoHeader";
import Footer from "../components/footer";

const Congratulation = () => {
  return (
    <div className="wrapper">
      <div className="content">
        <div className="center-page">
          <LogoHeader />
          <div className="block relative flex items-center justify-center w-full congrats-bg">
            <p
              style={{
                position: "absolute",
                color: "#0A0300",
                fontSize: "14px",
                fontFamily: "Sora",
                fontWeight: 500,
                wordWrap: "break-word",
                textAlign: "left",
                top: "60%",
                left: "70%",
                transform: "translate(-50%, -50%)",
                zIndex: 20,
                width: "224px",
              }}
            >
              Congratulations on taking the first step toward transforming your
              life! You’re almost ready to dive into the LoveGame universe,
              crafted to offer a one-of-a-kind journey. Just follow the link
              below to download our exclusive app from the App Store. We’re
              excited for you to get started!
            </p>
            {/* <img
              className="absolute z-0"
              src="/images/congrats-bg.svg"
              alt="congratulation-logo"
              style={{
                top: "0%",
                left: "46%",
                transform: "translate(-50%, 0)",
                maxWidth: "50%",
              }} // Background image remains fixed
            /> */}
            <img
              className="absolute z-10"
              src="/images/congrats-word.svg"
              alt="congratulation-logo"
              style={{
                top: "12%",
                left: "50%",
                transform: "translate(-50%, -50%)",
              }} // Adjusted to percentages
            />

            <img
              className="absolute z-10"
              src="/images/hearts.svg"
              alt="congratulation-logo"
              style={{
                bottom: "0%",
                left: "20%",
                transform: "translate(-50%, 50%)",
              }} // Adjusted to percentages
            />
          </div>
        </div>
        <Footer singleBtnDark="Go to the App Store" />
      </div>
    </div>
  );
};

export default Congratulation;
