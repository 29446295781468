import React from "react";
import Footer from "../components/footer";
import LogoHeader from "../components/logoHeader";

const PrePayment = () => {
  return (
    <div className="wrapper">
      <div className="content">
        <div className="center-page">
          <LogoHeader />
          <div className="block h-[62vh]">
            <img src="./images/unlock-title.svg" className="relative z-10" />
            <img
              src="./images/unlock-bg.png"
              className="rounded-2xl right-0 relative left-[9vh] top-[-6vh] h-[390px] w-[283px]"
            />
            <p
              style={{
                textAlign: "center",
                color: "#0A0300",
                fontSize: 14,
                fontFamily: "Sora",
                fontWeight: "500",
                position: "relative",
                bottom: "35vh",
              }}
            >
              LoveGame is your guide to <br /> strengthening your connection
              with
              <br />
              your partner through self-discovery and <br />
              fun challenges. By purchasing
              <br /> LoveGame, you're investing in a journey
              <br /> that will bring you even closer together.
            </p>
          </div>
          <div style={{ width: "100%", textAlign: "center", zIndex: 10 }}>
            <span
              style={{
                color: "#CA673C",
                fontSize: 18,
                fontFamily: "Sora",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            >
              Cost:{" "}
            </span>
            <span
              style={{
                color: "#CA673C",
                fontSize: 24,
                fontFamily: "Sora",
                fontWeight: "700",
                wordWrap: "break-word",
              }}
            ></span>
            <span
              style={{
                color: "#CA673C",
                fontSize: 20,
                fontFamily: "Sora",
                fontWeight: "400",

                wordWrap: "break-word",
              }}
            >
              €200 for two people
            </span>
          </div>{" "}
        </div>
        <Footer singleBtnDark="Go to payment" />
      </div>
    </div>
  );
};

export default PrePayment;
