import { incremented } from "@/lib/features/slices/screensSlice";
import { useAppDispatch } from "@/lib/hooks";
import React from "react";
import LogoHeader from "../components/logoHeader";
import Footer from "../components/footer";

const WelcomeScreen: React.FC = () => {
  const dispatch = useAppDispatch();
  return (
    <div className="wrapper welcome-screen">
      <div className="content">
        <div className="center-page">
          <LogoHeader />
          <img src="./images/play-game.svg" />
        </div>
        <p
          style={{
            width: "100%",
            textAlign: "center",
            color: "#0A0300",
            fontSize: 12,
            fontFamily: "Sora",
            fontWeight: "500",
            wordWrap: "break-word",
          }}
        >
          Embark on a 10-day journey, meticulously crafted to <br /> rekindle
          the spark in your relationship
        </p>
        <Footer singleBtnDark="Buy the LoveGame" />
      </div>
    </div>
  );
};

export default WelcomeScreen;
