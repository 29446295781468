"use client";
import { useRef } from "react";
import { useAppSelector, useAppStore } from "@/lib/hooks";
import { initializeScreen } from "@/lib/features/slices/screensSlice";
import Signup from "@/app/screens/signup";
import Payment from "@/app/screens/paymentScreen";
import Congratulation from "@/app/screens/congratulation";
import VerificationCode from "@/app/screens/verificationCode";
import PhoneScreen from "@/app/screens/phoneScreen";
import PrePayment from "@/app/screens/prePayment";
import WelcomeScreen from "@/app/screens/welcomeScreen";
import PartnerScreen from "@/app/screens/partnerScreen";
import Receipt from "@/app/screens/receipt";

export default function Home() {
  // Initialize the store with the product information
  const store = useAppStore();
  const initialized = useRef(false);
  if (!initialized.current) {
    store.dispatch(initializeScreen(0));
    initialized.current = true;
  }
  const { screenId } = useAppSelector((state) => state.screenDetails);

  // Define a function that returns the component based on the screenId
  const getCurrentScreen = () => {
    switch (screenId) {
      case 0:
        return <WelcomeScreen />;
      case 1:
        return <PhoneScreen />;
      case 2:
        return <VerificationCode />;
      case 3:
        return <Signup />;
      case 4:
        return <PrePayment />;
      case 5:
        return <Payment />;
      case 6:
        return <Receipt />;
      case 7:
        return <Congratulation />;
      // case 8:
      //   return <PartnerScreen />;
      // case 9:
      //   return <InvitationScreenTwo />;
      default:
        return <WelcomeScreen />;
    }
  };

  return (
    <div>
      {/* Render the current screen */}
      {getCurrentScreen()}
    </div>
  );
}
