import React from "react";
import { loadStripe } from "@stripe/stripe-js";
import { useAppSelector } from "@/lib/hooks";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./checkoutForm";

const stripePromise = loadStripe(
  process.env.NEXT_PUBLIC_STRIPE_PUBLISHABLE_KEY as string
);

const NewPaymentMethodsForm = (): JSX.Element => {
  const { clientSecret, paymentId } = useAppSelector(
    (state) => state.paymentMethod
  );

  return (
    <div>
      {clientSecret && (
        <Elements
          options={{
            clientSecret,
            appearance: {
              theme: "stripe",
            },
          }}
          stripe={stripePromise}
        >
          <CheckoutForm />
        </Elements>
      )}
    </div>
  );
};
export default NewPaymentMethodsForm;
