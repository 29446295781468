import React from "react";
import LogoHeader from "../components/logoHeader";
import Footer from "../components/footer";
import { useAppDispatch } from "@/lib/hooks";
import { decremented } from "@/lib/features/slices/screensSlice";

const Receipt = () => {
  const dispatch = useAppDispatch();

  return (
    <div className="wrapper">
      <div className="content">
        <div className="center-page">
          <LogoHeader />
          <div
            className="flex flex-col justify-center contents static payment-success "
            onClick={() => dispatch(decremented())}
          >
            <img
              src={"./images/radio-button.svg"}
              className="w-[36px] h-[36px]"
            />
            <h2>Payment Successful</h2>
            <h3>€200</h3>
          </div>
          <div
            style={{
              width: "27%",
              display: "flex",
              left: "-10%",
              margin: "0",
              justifyContent: "center",
              flexShrink: 0,
              flex: 1,
              position: "static",
              alignItems: "center",
            }}
          >
            <div className="relative h-[60vh] payment-success-bg">
              {/* <img
              src="./images/unlock-bg.png"
              className="rounded-2xl relative left-[-15vh] top-[2vh] h-[390px] w-[283px]z-0"
            /> */}
              <div className="payment-success-card">
                <ul>
                  <li className="flex justify-between mt-4 mb-4">
                    <p>Ref Number</p>
                    <span>000085752257</span>
                  </li>
                  <li className="flex justify-between mt-4 mb-4">
                    <p>Payment Time</p>
                    <span>25-02-2023, 13:22:16</span>
                  </li>
                  <li className="flex justify-between mt-4 mb-4">
                    <p>Payment Method</p>
                    <span>Visa ending in 2345</span>
                  </li>
                  <hr className="dashed-horizontal-line" />
                  <li className="flex justify-between mt-4 mb-4">
                    <p>1 x Lovegame for 2 persons:</p>
                    <span> €200 incl vat</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <Footer singleBtnDark="Done" />
      </div>
    </div>
  );
};

export default Receipt;
