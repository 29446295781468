import React from "react";
import LogoHeader from "../components/logoHeader";
import Footer from "../components/footer";
import SignUpForm from "../components/signUpForm";
import SignUpCheckBoxes from "../components/signUpCheckBoxes";

const Signup = () => {
  return (
    <div className="wrapper">
      <div className="content">
        <div className="center-page">
          <LogoHeader />
          <SignUpForm />
          <SignUpCheckBoxes />
        </div>{" "}
        <Footer singleBtnDark="Next" />
      </div>
    </div>
  );
};

export default Signup;
