import React from "react";
import LogoHeader from "../components/logoHeader";
import NewPaymentMethodsForm from "../components/payment/newPaymentMethodsForm";

const PaymentScreen = () => {
  return (
    <div className="wrapper">
      <div className="content">
        <div className="center-page">
          <LogoHeader />
          <NewPaymentMethodsForm />
        </div>
      </div>
    </div>
  );
};

export default PaymentScreen;
